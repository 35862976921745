@import "../../App.scss";

.onlinemeet {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: grid;
  place-items: center;

  .wrapper {
    width: 96%;
    height: 96%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--background-color);
    border-radius: 15px;
    box-shadow: 0px 0px 2px 2px #eeeeee;
    // overflow: hidden;

    .video-section {
      width: 62%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      box-shadow: 0px 0px 2px 2px #eeeeee;
      background: var(--secondary-color);

      video {
        width: 100%;
        height: 90%;
        border-top-left-radius: 15px;
        object-fit: cover;
      }

      .controls {
        width: 65%;
        height: 10%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          background-color: var(--secondary-color);
          border: none;
          border-radius: 20px;
          box-shadow: 2px 2px 20px #d1d1d1;

          &.btn {
            width: 65px;
            color: var(--text-color2);
            border-radius: 20px;

            &:hover {
              background: var(--text-color1);
              color: var(--secondary-color);
            }
          }

          &.leaveBtn {
            padding: 5px 22px;
            font-size: 14px;
            font-weight: 500;
            color: red;
            border: 2px solid red;
            border-radius: 50px;

            &:hover {
              background: red;
              color: var(--secondary-color);
            }
          }

          &.moreBtn {
            display: none;
          }
        }
      }
    }

    .screens-section {
      width: 15%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .heading {
        display: flex;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 10px;
        font-size: 14px;
        font-weight: 500;
        color: var(--text-color2);
      }

      .screens {
        width: 90%;
        display: flex;
        flex-direction: column;

        .screen {
          width: 100%;
          height: 140px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 15px;
          cursor: pointer;

          video {
            width: 100%;
            height: 85%;
            object-fit: cover;
            margin-bottom: 5px;
            border-radius: 15px;
          }

          p {
            color: var(--text-color4);
            font-size: 14px;
            font-weight: 500;
          }
        }
      }
    }

    .right-panel {
      width: 22%;
      height: 100%;
      display: flex;
      flex-direction: column;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      box-shadow: 0px 0px 2px 2px #eeeeee;

      .topSection {
        width: 100%;
        height: 8%;
        background: var(--secondary-color);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        border-bottom: 2px solid var(--primary-color);
        border-top-right-radius: 15px;

        .backBtn {
          display: none;
        }

        p.rightNavLink {
          font-size: 15px;
          font-weight: 500;
          color: var(--text-color2);
          cursor: pointer;
        }

        p.rightNavLink.active {
          color: var(--primary-color);
        }
      }

      .middleSection {
        width: 100%;
        height: 92%;
        // margin-top: 15px;
        // padding-right: 15px;

        .chats {
          width: 100%;
          height: 92%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 15px;
          padding-right: 15px;
        }

        .msgForm {
          width: 100%;
          height: 8%;
          background: var(--secondary-color);
          display: flex;
          align-items: center;
          border-top: 2px solid var(--primary-color);
          border-bottom-right-radius: 15px;

          input {
            width: 85%;
            height: 100%;
            padding: 5px 20px 5px 25px;
            border: none;
            outline: none;
          }

          button {
            background: none;
            border: none;
            color: var(--text-color2);
            cursor: pointer;

            &:disabled {
              cursor: initial;
              color: var(--text-color1);
            }
          }
        }

        .participants {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 15px;
          padding-right: 15px;

          .participant {
            width: 82%;
            height: 50px;
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            img {
              height: 42px;
              width: 42px;
              border-radius: 50px;
              object-fit: cover;
            }

            h6 {
              font-size: 14px;
              font-weight: 600;
              color: var(--text-color2);
              margin-left: 20px;
              margin-right: auto;
            }
          }
        }

        .meeting-info {
          width: 100%;
          height: 100%;
          padding-top: 15px;
          padding-right: 15px;
          padding-left: 20px;

          h6 {
            font-size: 16px;
            color: var(--text-color2);
            font-weight: 600;
            margin-bottom: 15px;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            color: var(--text-color1);
            margin-bottom: 15px;
          }
        }
      }
    }
  }

  @include tablet {
    .wrapper {
      width: 95vw;
      height: 96vh;
      flex-direction: column;
      background: none;
      border-radius: 0;
      box-shadow: none;

      .video-section {
        width: 100%;
        height: 73%;
        border-radius: 8px;
        overflow: hidden;

        .display-image {
          height: 100%;
          border-radius: 8px;
        }

        .controls {
          position: absolute;
          bottom: 1.5vh;
          width: 50vw;

          button {
            width: 50px;
            height: 50px;

            &.btn {
              width: 50px;
              height: 50px;
              border-radius: 50px;
              order: 2;
            }

            &.mobile-hide {
              display: none;
            }

            &.moreBtn {
              display: block;
              order: 3;
            }

            &.leaveBtn {
              order: 1;
            }
          }
        }
      }

      .screens-section {
        width: 100%;
        height: 13%;
        margin-bottom: 15%;

        .heading {
          display: none;
        }

        .screens {
          width: 100%;
          height: 100%;
          flex-direction: row;
          overflow: auto;

          .screen {
            min-width: 22vw;
            max-width: 22vw;
            height: 96%;
            margin-bottom: 0;
            box-shadow: 0px 0px 2px 2px #eeeeee;
            border-radius: 10px;
            margin-right: 10px;

            img {
              height: 75%;
              border-radius: 10px 10px 0 0;
              margin-bottom: 3px;
            }

            p {
              font-size: 13px;
              margin-bottom: 3px;
            }
          }
        }
      }

      .right-panel {
        display: none;

        &.fullscreen {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          height: 100vh;
          background: white;

          .topSection {
            padding-left: 10px;
            padding-right: 60px;
            justify-content: space-between;

            .backBtn {
              display: block;
              background: none;
              border: none;
              color: var(--text-color2);
              width: 40px;
            }
          }
        }
      }
    }
  }

  @include mobile {
    .wrapper {
      .video-section {
        height: 70vh;

        .controls {
          width: 80vw;
        }
      }

      .screens-section {
        height: 13vh;
        margin-bottom: 20%;
      }
    }
  }

  @include xs {
    .wrapper {
      .screens-section {
        margin-bottom: 21%;
      }
    }
  }
}
