@import "../../App.scss";

.individual-doubt {
  .container {
    .wrapper {
      width: 66%;
      margin-left: 13px;

      @include tablet {
        width: 100%;
        margin: 0;
      }

      .writeAnswer {
        margin-top: 30px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        background: var(--secondary-color);
        padding: 16px 22px;
        box-shadow: 2px 2px 20px #d1d1d1;
        border-radius: 12px;

        img {
          width: 48px;
          height: 48px;
          object-fit: cover;
          border-radius: 50px;
        }

        .inputSection {
          width: 93%;
          display: flex;
          flex-direction: column;

          textarea {
            width: 100%;
            min-height: 100px;
            max-height: 350px;
            resize: vertical;
            margin-bottom: 10px;
            padding: 6px;
            border: 2px solid var(--text-color1);
            border-radius: 5px;
            outline: none;

            &:focus {
              box-shadow: 0px 0px 2px 2px #eeeeee;
            }
          }

          button {
            align-self: flex-end;
            width: 95px;
            height: 36px;
            background: var(--primary-gradient);
            color: var(--secondary-color);
            letter-spacing: 0.5px;
            font-weight: 500;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px;
            border-radius: 50px;
            border: none;
            cursor: pointer;
          }
        }

        @include tablet {
          width: 100%;
          padding: 16px 18px;
          margin-top: 20px;

          img {
            margin-right: 8px;
          }

          .inputSection {
            width: 90%;
          }
        }

        @include mobile {
          img {
            width: 40px;
            height: 40px;
          }

          .inputSection {
            width: 84%;
          }
        }
      }

      .comment-heading {
        margin: 32px 0 20px;
        display: flex;

        .icon {
          color: var(--text-color2);
          margin-right: 8px;
        }

        h3 {
          color: var(--text-color2);
        }

        @include tablet {
          margin-top: 20px;
        }
      }
    }
  }
}
