:root {
  --primary-color: #646cff;
  --secondary-color: #ffffff;
  --background-color: #f5f8fd;

  --text-color1: #919191;
  --text-color2: #505050;
  --text-color3: #ffc400;
  --text-color4: #000000;

  --extra-color1: #5fd058;
  --extra-color2: #72dfee;
  --extra-color3: #ef8b3e;
  --extra-color4: rgba(100, 108, 255, 0.1);

  --primary-gradient: linear-gradient(90deg, #a3a7ed 0%, #646cff 100%);
}

* {
  font-family: "Inter", sans-serif;
}

@mixin tablet {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: 359px) {
    @content;
  }
}

.app {
  width: 96%;
  margin: 84px auto 0 auto;

  .link {
    text-decoration: none;
  }

  .container {
    padding: 30px 20px 50px 30px;
    background: var(--background-color);

    @include tablet {
      padding: 20px 30px 80px;
    }

    @include mobile {
      padding: 20px 10px 70px;
    }

    .widgets-row {
      width: 68%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include tablet {
        width: 100%;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  @include tablet() {
    width: 100%;
    margin-top: 60px;
  }

  @include mobile {
    margin-top: 54px;
  }
}
