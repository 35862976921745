@import "../../App.scss";

.itemMd {
  width: 100%;
  height: 92px;
  background: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 25px 12px;
  box-shadow: 2px 2px 20px #d1d1d1;
  border-radius: 12px;

  img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 50%;
  }

  h4, p {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
  }

  .textcontent {
    width: 160px;
    height: 84%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2px;

    .itemTitle {
      font-weight: 600;
      color: var(--text-color2);
    }

    .subject {
      font-size: 14px;
      font-weight: 500;
      color: var(--text-color1);
    }

    p {
      font-size: 12px;
      font-weight: normal;
      color: var(--text-color1);
    }
  }

  .right {
    width: 100px;

    button {
      width: 88px;
      height: 38px;
      padding: 8px 24px;
      font-weight: 600;
      background: var(--secondary-color);
      color: var(--text-color3);
      border: 2px solid var(--text-color3);
      border-radius: 50px;
      transition: all 0.3s ease-out;
      cursor: pointer;

      &:hover {
        background: var(--primary-gradient);
        border: none;
        color: var(--secondary-color);
      }
    }
  }

  &.task.first {
    height: 100px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0px 10px 30px 12px #d1d1d1;
  }

  &.task.second {
    height: 100px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  &.task {
    .textcontent {
      width: 170px;
      height: 100%;

      .taskfile {
        max-width: 85%;
        display: flex;
        align-items: center;
        padding: 1px 10px;
        border: 2px solid var(--primary-color);
        border-radius: 50px;

        .icon {
          font-size: 16px;
          color: var(--primary-color);
          margin-right: 5px;
        }

        p {
          color: var(--primary-color);
        }
      }
    }

    .right {
      height: 85%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 12px;
        font-weight: normal;
        color: var(--text-color1);
      }

      &.submitted {
        justify-content: center;

        p {
          color: var(--extra-color1);
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }

  &.class {
    // margin-left: 12px;
    width: 100%;
    background: var(--secondary-color);
    box-shadow: none;
    padding: 15px 15px 15px 8px;

    .subject-icon {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50px;

      h5 {
        font-size: 18px;
        font-weight: 600;
      }
    }

    .textcontent {
      width: 150px;
    }

    .right {
      width: min-content;

      .link {
        button {
          width: 75px;
          height: 30px;
          padding: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:first-of-type {
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }

  @include tablet {
    padding: 12px 18px;
    column-gap: 15px;

    .textcontent {
      width: 54%;

      // .itemTitle {
      //   font-size: 2.4vw;
      // }

      // .subject {
      //   font-size: 2.2vw;
      // }

      // p {
      //   font-size: 1.9vw;
      // }
    }

    &.task {
      .textcontent {
        width: 54%;
      }
    }
  }

  @include mobile {
    height: 80px;
    padding: 12px 18px;
    column-gap: 15px;

    img {
      width: 40px;
      height: 40px;
    }

    .textcontent {
      width: 54%;
      height: 13vw;

      .itemTitle {
        font-size: 4vw;
      }

      .subject {
        font-size: 3.5vw;
      }

      p {
        font-size: 3vw;
      }
    }

    &.task {
      .textcontent {
        width: 54%;
        height: 100%;

        .taskfile {
          padding: 0px 10px;

          .icon {
            font-size: 13px;
          }

          p {
            font-size: 11px;
          }
        }
      }

      .right,
      .right.submitted {
        height: 100%;
        min-width: 22%;

        p {
          font-size: 12px;
        }
      }
    }

    &.task.first, &.task.second {
      height: 85px;
    }

    .right {
      width: max-content;

      .link {
        button {
          width: 70px;
          height: 32px;
          padding: 0;
          font-size: 13px;
        }
      }
    }
  }

  @include xs {
    .textcontent {
      width: 80%;
      height: 15vw;

      .itemTitle {
        font-size: 4.1vw;
      }

      .subject {
        font-size: 3.8vw;
      }

      p {
        font-size: 3.5vw;
      }
    }

    .right {
      display: none;
    }

    &.task {
      .textcontent {
        width: 80%;
      }

      .right {
        display: none;
      }
    }
  }
}
