@import "../../App.scss";

.comment {
  border: 2px solid none;
  padding: 20px;
  width: 100%;
  border-radius: 15px;
  background: var(--secondary-color);
  display: flex;
  box-shadow: 2px 2px 20px #e0e0e0;
  margin-bottom: 15px;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 15px;
  }

  .textContent {
    width: 90%;

    .topSection {
      display: flex;
      margin-bottom: 5px;

      h4 {
        font-size: 14px;
        font-weight: 600;
        color: var(--text-color2);
      }

      p {
        font-size: 13px;
        margin-left: 20px;
        color: var(--text-color1);
      }
    }

    .message {
      font-size: 14px;
      line-height: 1.35;
    }
  }

  @include mobile {
    padding: 15px;

    .textContent {
      width: 80%;
      .message {
        font-size: 12px;
      }
    }

    img {
      width: 40px;
      height: 40px;
    }
  }

  &.writeComment {
    display: flex;
    border: 2px solid none;
    padding: 20px;
    border-radius: 15px;
    background: var(--secondary-color);
    justify-content: flex-start;

    .inputSection {
      width: 90%;
      display: flex;
      align-items: center;
      border: 2px solid var(--text-color1);
      border-radius: 50px;
      padding-left: 20px;

      input {
        width: 94%;
        height: 90%;
        border: none;
        outline: none;
      }

      button {
        border: none;
        background: none;
        cursor: pointer;
        height: 40px;

        .icon {
          color: var(--text-color2);
        }
      }
    }
    @include tablet {
      button {
        margin-right: 14px;
      }
    }
    @include mobile {
      padding: 15px;

      .inputSection {
        width: 80%;

        input {
          font-size: 12px;
        }
        button {
          margin-right: 0px;
        }

        .cancelBtn {
          padding: 12px 0;
        }
      }
    }
  }
}
