@import "../../App.scss";

.register {
  transform: rotate(180deg);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-gradient);
  display: flex;
  align-items: center;
  justify-content: center;
  
  .register-page {
    transform: rotate(180deg);
    margin: auto;
    width: 500px; // Increased width to accommodate horizontal fields
    min-height: min-content; // Changed to min-content to avoid extra space
    background: var(--secondary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 2rem;
    position: relative;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 8px;
      background: var(--primary-gradient);
    }

    h1 {
      color: var(--primary-color);
      margin-bottom: 2rem;
      font-size: 2rem;
      font-weight: 600;
      text-align: center;
      position: relative;
      
      &::after {
        content: '';
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translateX(-50%);
        width: 50px;
        height: 3px;
        background: var(--primary-gradient);
        border-radius: 2px;
      }
    }

    // New horizontal row container
    .input-row {
      display: flex;
      gap: 1rem;
      width: 100%;
      margin-bottom: 1.2rem;
    }

    .register-input {
      margin-bottom: 1.2rem;
      border: 2px solid transparent;
      border-radius: 12px;
      display: flex;
      align-items: center;
      width: 100%;
      background: #f5f5f5;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;

      // Remove margin-bottom when inside input-row
      .input-row & {
        margin-bottom: 0;
        width: 50%; // Each input takes half the space in a row
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 2px;
        background: var(--primary-gradient);
        transition: width 0.3s ease;
      }

      &:focus-within {
        border-color: var(--primary-color);
        background: white;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

        &::before {
          width: 100%;
        }

        .icon {
          transform: scale(1.1);
          color: var(--primary-color);
        }
      }

      input {
        border-radius: 12px;
        border: none;
        outline: none;
        color: var(--text-color2);
        padding: 0.8rem 1rem;
        width: 100%;
        font-size: 14px;
        background: transparent;
        transition: all 0.3s ease;

        &::placeholder {
          color: #999;
          transition: all 0.3s ease;
        }

        &:focus::placeholder {
          opacity: 0.7;
          transform: translateX(5px);
        }
      }

      .icon {
        color: #999;
        margin: 0 1rem;
        transition: all 0.3s ease;
        font-size: 1.2rem;
      }
    }

    .btn {
      width: 100%;
      background: var(--primary-gradient);
      color: white;
      font-size: 1.1rem;
      font-weight: 500;
      padding: 0.8rem;
      margin: 0.8rem 0;
      border: none;
      border-radius: 12px;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          transparent,
          rgba(255, 255, 255, 0.2),
          transparent
        );
        transition: 0.5s;
      }

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 7px 14px rgba(var(--primary-color-rgb), 0.2);

        &::before {
          left: 100%;
        }
      }

      &:active {
        transform: translateY(0);
      }

      &:disabled {
        opacity: 0.7;
        cursor: not-allowed;
        transform: none;
      }
    }

    div:not(.register-input):not(.input-row) {
      color: #999;
      margin: 0.5rem 0;
      position: relative;
      text-align: center;
      
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        width: 45%;
        height: 1px;
        background: #ddd;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }

    // Error message styling
    p[style*="color: red"] {
      background: rgba(255, 0, 0, 0.1);
      color: #e74c3c !important;
      padding: 0.8rem;
      border-radius: 8px;
      font-size: 13px !important;
      margin: 0.5rem 0;
      text-align: center !important;
      width: 100%;
    }
  }

  @include tablet {
    display: block;
    background: var(--primary-gradient);

    .register-page {
      margin: 2rem auto;
      width: 90%;
      max-width: 500px;

      .input-row {
        flex-direction: column;
        gap: 1.2rem;

        .register-input {
          width: 100%;
        }
      }
    }
  }

  @include mobile {
    .register-page {
      margin: 1rem auto;
      width: 95%;
      padding: 1.5rem;

      h1 {
        font-size: 1.75rem;
      }

      .register-input {
        margin-bottom: 1rem;
        
        input {
          padding: 0.8rem 1rem;
        }
      }

      .btn {
        padding: 0.8rem;
        font-size: 1rem;
      }

      .input-row {
        flex-direction: column;
        gap: 1rem;

        .register-input {
          width: 100%;
        }
      }
    }
  }
}