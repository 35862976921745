@import "../../App.scss";

.individual-task {
  .container {
    .wrapper {
      width: 66%;
      margin-left: 13px;

      @include tablet {
        width: 100%;
        margin: 0;
      }

      .comment-heading {
        margin: 32px 0 20px;
        display: flex;

        .icon {
          color: var(--text-color2);
          margin-right: 15px;
        }

        h3 {
          color: var(--text-color2);
        }
      }
    }
  }
}
