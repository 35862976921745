@import "../../App.scss";

.heroSection {
  background: var(--primary-gradient);
  height: 220px;
  width: 68%;
  border-radius: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px 15px 15px;

  @include tablet {
    height: 140px;
    width: 100%;
  }

  @include xs {
    height: 120px;

    img {
      display: none;
    }
  }

  .heroSection-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5px;

    .left {
      position: relative;
      top: -23px;
      left: -20px;

      img {
        height: 270px;
      }

      @include tablet {
        top: -15px;
        left: 0px;

        img {
          height: 174px;
        }
      }
    }

    .right {
      color: var(--secondary-color);
      letter-spacing: 1px;
      text-align: left;
      margin-left: 20px;

      h3 {
        font-size: 15px;
      }

      h1 {
        font-size: 40px;
        margin-top: 6px;
        margin-bottom: 6px;
      }

      p {
        font-size: 15px;
      }

      @include tablet {
        width: 75%;
        margin-left: 20px;

        h3 {
          font-size: 15px;
          font-weight: 600;
        }

        h1 {
          font-size: 24px;
          font-weight: 600;
          line-height: 1.2;
        }

        p {
          font-size: 14px;
          font-weight: 300;
        }
      }

      @include mobile {
        width: max-content;
        margin-left: 0px;

        h3 {
          font-size: 3.2vw;
        }

        h1 {
          font-size: 4.4vw;
        }

        p {
          font-size: 2.6vw;
        }
      }

      @include xs {
        margin-left: 10px;

        h3 {
          font-size: 3.5vw;
        }

        h1 {
          font-size: 6vw;
        }

        p {
          font-size: 3vw;
        }
      }
    }
  }

  &.small {
    height: 130px;
    display: flex;
    align-items: flex-start;
    
    @include tablet {
      height: 105px;
    }
    
    .heroSection-content {
      height: 100%;
      justify-content: flex-start;
      
      .right {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        padding: 25px;
        margin-left: 0;

        h1 {
          text-transform: capitalize;
        }
      }

      @include tablet {
        justify-content: flex-start;

        .right {
          padding-left: 15px;

          h1 {
            font-size: 24px;
            font-weight: 700;
            margin-top: 0;
          }

          h3 {
            font-weight: 600;
          }
        }
      }

      @include mobile {
        .right {
          padding: 25px 5px;

          h1 {
            font-size: 20px;
          }
        }
      }

      @include xs {
        .right {
          padding: 25px 0;

          h1 {
            font-size: 18px;
          }
        }
      }
    }
  }
}
