@import "../../App.scss";

.sidebar {
  width: 29%;
  height: max-content;
  background: var(--secondary-color);
  float: right;
  position: absolute;
  top: 86px;
  right: 2%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0px 0px 2px 2px #eeeeee;
  padding: 25px 35px;

  h2 {
    font-size: 24px;
    color: var(--text-color2);
    margin-bottom: 8px;
  }

  .calendar {
    width: 370px;
    border: none;
    position: relative;
    left: -12px;
  }

  .all-classes {
    width: 90%;
    height: 100%;
  }

  @include tablet {
    display: none;
  }
}
