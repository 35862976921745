@import "../../App.scss";

.joinClass {
  width: 100%;
  height: 88vh;
  background: var(--background-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 100px 40px;

  .meeting {
    width: 65%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .meeting-code {
      width: 100%;
      font-size: 20px;
      color: var(--text-color2);
    }

    .meeting-screen {
      width: 100%;
      height: 82%;
      background: var(--secondary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      box-shadow: 0px 0px 2px 2px #eeeeee;

      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    .meeting-controls {
      width: 50%;
      display: flex;
      justify-content: space-evenly;

      button {
        width: 70px;
        height: 50px;
        border: none;
        border-radius: 16px;
        background: var(--secondary-color);
        box-shadow: 0px 0px 2px 2px #eeeeee;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          color: var(--text-color2);
          font-size: 26px;
        }

        &:hover {
          background: var(--text-color1);

          .icon {
            color: var(--secondary-color);
          }
        }
      }
    }
  }

  .join-meeting {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      font-size: 40px;
      color: var(--text-color2);
      padding: 20px;
      margin-bottom: 5px;
    }

    .btn-container {
      display: flex;
      flex-direction: column;
      align-items: center;

      .link {
        button {
          background: var(--primary-gradient);
          width: 170px;
          height: 40px;
          color: var(--secondary-color);
          font-weight: 700;
          border: none;
          border-radius: 26px;
          padding: 10px 20px;
          cursor: pointer;
        }

        .createBtn {
          display: flex;
          align-items: center;
          justify-content: center;

          .icon {
            margin-right: 8px;
          }
        }
      }

      h3 {
        text-align: center;
        color: var(--text-color2);
        padding: 13px;
        font-size: 30px;
        width: 100%;
      }
    }
  }

  @include tablet {
    height: max-content;
    flex-direction: column;
    margin-bottom: 40px;
    padding: 35px;

    .meeting {
      width: 70%;
      height: 70vh;
      margin-bottom: 15px;

      .meeting-screen {
        height: 78%;

        img {
          width: 30vw;
          height: 30vw;
        }
      }

      .meeting-controls {
        width: 60%;

        button {
          width: 54px;
          height: 54px;
          border-radius: 50px;
        }
      }
    }

    .join-meeting {
      h1 {
        display: none;
      }

      .btn-container {
        flex-direction: row;

        .link {
          button {
            height: 45px;
            font-size: 14px;
          }
        }

        h3 {
          font-size: 20px;
          margin: 0px 15px;
        }
      }
    }
  }

  @include mobile {
    padding: 15px 20px;
    margin-bottom: 60px;

    .meeting {
      width: 82%;

      .meeting-code {
        font-size: 18px;
        text-align: center;
      }

      .meeting-controls {
        width: 100%;

        button {
          width: 50px;
          height: 50px;
        }
      }
    }

    .join-meeting {
      .btn-container {
        flex-direction: column;
      }
    }
  }
}
