@import "../../App.scss";

.itemSm {
  width: 100%;
  height: 62px;
  background: var(--secondary-color);
  padding: 15px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 2px 20px #d1d1d1;
  border-radius: 12px;

  h5 {
    font-size: 14px;
    font-weight: 500;
    color: var(--text-color2);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
  }

  p {
    font-size: 12px;
    color: var(--text-color1);
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: clip;
  }

  .column1 {
    width: 80px;
    display: flex;
    align-items: center;

    h5 {
      max-width: 66px;
      overflow: hidden;
    }

    .status-indicator {
      min-width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 5px;

      &.finished {
        background: var(--extra-color1);
      }

      &.ongoing {
        background: var(--text-color3);
      }

      &.not-started {
        background: var(--extra-color2);
      }
    }
  }

  .column2 {
    width: 106px;

    h5 {
      padding-bottom: 6px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .column3 {
    width: 65px;

    h5 {
      padding-bottom: 6px;
    }
  }

  .column4 {
    width: 72px;
    text-align: center;

    p {
      font-size: 13px;
      font-weight: 500;
    }

    p.finished {
      color: var(--extra-color1);
    }

    p.not-started {
      color: var(--extra-color2);
    }

    .link {
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 72px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px;
        font-weight: 600;
        background: var(--secondary-color);
        color: var(--text-color3);
        border: 2px solid var(--text-color3);
        border-radius: 50px;
        transition: all 0.3s ease-out;
        cursor: pointer;
      }
    }
  }

  &:hover {
    background: var(--primary-gradient);
    transition: all 0.3s ease-out;
    cursor: pointer;

    h5,
    p {
      color: var(--secondary-color);
    }

    .column4 {
      button {
        background: var(--text-color3);
        color: var(--secondary-color);
      }
    }
  }

  &.schedule.finished,
  &.schedule.not-started:hover {
    cursor: initial;
  }

  &.doubt {
    .column1 {
      width: 86px;
      flex-direction: column-reverse;
      align-items: flex-start;

      h5 {
        margin-bottom: 6px;
        max-width: 86px;
      }
    }

    .column2 {
      width: 225px;
    }
  }

  @include mobile {
    padding: 12px;

    h5 {
      font-size: 3.4vw;
    }

    p {
      font-size: 3vw;
    }

    .column4 {
      p.finished,
      p.not-started {
        font-size: 3vw;
      }

      .link {
        button {
          width: 58px;
          height: 28px;
          padding: 0;
          font-size: 11px;
        }
      }
    }

    &.schedule {
      .column1 {
        width: 22%;
      }

      .column2 {
        width: 34%;
      }

      .column3 {
        width: 18%;
      }

      .column4 {
        width: 18%;
      }
    }

    &.doubt {
      .column1 {
        width: 20%;
      }

      .column2 {
        width: 50%;
      }
    }
  }

  @include xs {
    h5 {
      font-size: 3.7vw;
    }

    p {
      font-size: 3.4vw;
    }

    .column4 {
      display: none;
    }

    
    &.schedule {
      .column1 {
        width: 25%;
      }

      .column2 {
        width: 42%;
      }

      .column3 {
        width: 22%;
      }
    }

    &.doubt {
      .column1 {
        width: 26%;
      }

      .column2 {
        width: 68%;
      }
    }
  }
}
